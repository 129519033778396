import React from 'react'
import './prosCons.scss'

const icon = (item) => {
  if (item.type === 'pros') {
    return (
      <i className="fas fa-check mr-2 proscons-icon"></i>
    )
  }

  if (item.type === 'cons') {
    return (
      <i className="fas fa-times mr-2 proscons-icon"></i>
    )
  }
}

const title = (item) => {
  if (item.type === 'pros') {
    return ('Pros')
  }

  if (item.type === 'cons') {
    return ('Cons')
  }
}

const renderItems = (item) => {
  return (
    <div key={item.key} className={item.type}>
      <div className="proscons-title">{title(item)}</div>
      <ul className="proscons-ul">
        {item.props.children.map(pro => {
          if (pro.type === 'span') {
            return (
              <li className="proscons-li" key={pro.key}>{icon(item)}{pro.props.children.map(text => text)}</li>
            )
          }
        })}
      </ul>
    </div>
  )
}

const ProsCons = ({children}) => {
  return (
    <div>
      {children.map(item => {
        if (item.type === 'pros' || item.type === 'cons') {
          return (
            renderItems(item)
          )
        }
      })}
    </div>
  )
}

export default ProsCons

import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"
import { Col, Row, Badge } from 'reactstrap'
import './magazineSubscribe.scss'

function renderMailChimpForm () {
  return {__html: `
    <div>
      <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css"/>
      <div id="mc_embed_signup">
        <form action="" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <div id="mc_embed_signup_scroll">
            <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Enter email address" required/>
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_43780426e2b59a9344bb2966c_85cb8bebd1" tabindex="-1" value=""/></div>
            <div class="clear"><input type="submit" value="Download" name="subscribe" id="mc-embedded-subscribe" class="button"/></div>
          </div>
        </form>
      </div>
    </div>`
  }
}

function renderContainer (data, title, content) {
  return (
    <div className="magazine-subscribe__container">
      <Row className="magazine-subscribe__row">
        <Col align="center">
          <Img className="magazine-subscribe__img" fluid={{...data.file.childImageSharp.fluid}} alt="Weighted Blanket Guides Magazine"/>
        </Col>
        <Col>
          <Badge color="danger">New</Badge>
          <div><strong>{title}</strong></div>
          {/* <br/> */}
          <div>{content}</div>
          <div dangerouslySetInnerHTML={renderMailChimpForm()}></div>
        </Col>
      </Row>
    </div>
  )
}

const MagazineSubscribe = (props) => {
  const {
    title = 'Download the FREE Magazine',
    content = 'Let us send you the Fall & Winter edition of our magazine.'
  } = props

  return (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: {eq: ""}) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => renderContainer(data, title, content)}
  />)
}

export default MagazineSubscribe

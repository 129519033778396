import React from 'react'
import { Button } from 'reactstrap'
import './amazonButton.scss'

const AmazonButton = (props) => {

  const align = (align) => {
    if (align) {
      switch (align) {
        case 'left':
          return ('')
        case 'right':
          return ('text-right')
        case 'center':
        default:
          return ('text-center')
      }
    }
  }

  return (
    <div className={align(props.align)}>
      <a href={props.href} target="_blank" role="button" rel="nofollow">
        <Button color="warning" className="colorAmazonYellow" size="lg"><i className="fab fa-amazon mr-2"></i>{props.text}</Button>
      </a>
    </div>
  )
}

export default AmazonButton

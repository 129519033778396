import React from 'react'

const Spacer = (props) => {
  const { size = 5 } = props
  const sizeClass = 'my-' + size

  return (
    <div className={sizeClass}></div>
  )
}

export default Spacer

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import rehypeReact from 'rehype-react'

import Layout from '../components/layout/Layout'
import Columns from '../components/columns/Columns'
import AmazonButton from '../components/amazonButton/AmazonButton'
import CtaButton from '../components/ctaButton/CtaButton'
import Spacer from '../components/spacer/Spacer'
import Counter from '../components/counter/Counter'
import ProductTable from '../components/productTable/ProductTable'
import ProsCons from '../components/prosCons/ProsCons'
import MagazineSubscribe from '../components/magazineSubscribe/MagazineSubscribe'
import SEO from '../components/seo/SEO'
import References from '../components/references/References'
import WeightedBlanketCalculator from '../components/weightedBlanketCalculator/WeightedBlanketCalculator'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const allPosts = this.props.data.allMarkdownRemark.edges
    const layout = get(post, 'frontmatter.layout')

    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: {
        'columns': Columns,
        'amazonbutton': AmazonButton,
        'ctabutton': CtaButton,
        'spacer': Spacer,
        'counter': Counter,
        'producttable': ProductTable,
        'proscons': ProsCons,
        'magazinesubscribe': MagazineSubscribe,
        'references': References,
        'weightedblanketcalculator': WeightedBlanketCalculator
      }
    }).Compiler

    return (
      <Layout location={this.props.location} layout={layout} posts={allPosts} category={post.frontmatter.category}>
        <SEO postData={post} postImage={post.frontmatter.coverImage.publicURL} isBlogPost={true} config={this.props.data.site.siteMetadata}/>
        <div className="post__container">
          <h1  className="mb-0">{post.frontmatter.title}</h1>
          <div className="post__container-dateModified"><i>Last Update: {post.fields.dateModifiedFormatted} &nbsp;&nbsp; {post.fields.author ? `Author: ${post.fields.author}` : ``}</i></div>
          <div>{renderAst(post.htmlAst)}</div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        name
        author
        description
        siteUrl
        url
        logo
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 155)
      htmlAst
      fields {
        slug
        category
        metaDescription
        dateModifiedFormatted(formatString: "MMMM Do, YYYY")
        author
      }
      frontmatter {
        title
        datePublished
        dateModified
        layout
        category
        coverImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1240 ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
        sort: { fields: [frontmatter___dateModified], order: DESC },
        filter:{ fileAbsolutePath: {regex : "\/posts/"} }
    ) {
        edges {
          node {
            ...SidebarFragment
          }
        }
    }
  }
`

import React, { useState } from "react"
import { Card, Form, FormGroup, Button, InputGroup, Input, InputGroupText, Label, Row, Col } from 'reactstrap'
import './weightedblanketcalculator.scss'

const WeightedBlanketCalculator = () => {
  const [bodyWeight, setBodyWeight] = useState("")
  const [recommendedWeight, setRecommendedWeight] = useState("")

  const handleCalculate = () => {
    setRecommendedWeight(Math.ceil(bodyWeight * 0.1))
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      handleCalculate()
    }
  }

  return (
    <div className="weightedblanketcalculator">
      <Card color="light" className="p-3">
        <Form>
          <FormGroup>
            <Label for="bodyweight">
              Please enter your body weight in pounds:
            </Label>
            <InputGroup>
              <Input
                name="bodyweight"
                type="number"
                placeholder="enter body weight"
                value={bodyWeight}
                bsSize="lg"
                onChange={e => setBodyWeight(e.target.value)}
                onKeyPress={handleKeyPress} />
              <InputGroupText>lbs</InputGroupText>
              <Button onClick={handleCalculate} color="success" className="ml-2">Calculate!</Button>
            </InputGroup>
            <Label for="blanketweight" className="pt-4">
              Calculated weight of weighted blanket:
            </Label>
            <Row>
              <Col md={6}>
                <InputGroup>
                  <Input
                    className="blanketweighthighlight"
                    disabled
                    name="blanketweight"
                    bsSize="lg"
                    value={recommendedWeight} />
                  <InputGroupText>lbs</InputGroupText>
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </Card>
    </div>
  )
}

export default WeightedBlanketCalculator

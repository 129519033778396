import React from 'react'
import { Row, Col } from 'reactstrap'
import AmazonButton from '../amazonButton/AmazonButton'
import Spacer from '../spacer/Spacer'
import Counter from '../counter/Counter'
import ProsCons from '../prosCons/ProsCons'
import './columns.scss'

const amazonButtonItem = (item) => {
  return (
    <AmazonButton key={item.key} href={item.props.href} text={item.props.text} align={item.props.align}></AmazonButton>
  )
}

const spacer = (item) => {
  return (
    <Spacer key={item.key} size={item.props.size}></Spacer>
  )
}

const counter = (item) => {
  return (
    <Counter key={item.key} number={item.props.number} subtitle={item.props.subtitle}></Counter>
  )
}

const proscons = (item) => {
  return (
    <ProsCons key={item.key}></ProsCons>
  )
}

const align = (align) => {
  if (align) {
    switch (align) {
      case 'left':
        return ('')
      case 'right':
        return ('text-right')
      case 'center':
      default:
        return ('text-center')
    }
  }
}

const Columns = (props) => {
  const { children, border = false } = props
  const borderClass = border === 'true' ? 'columns-border' : ''
  const rowClasses = `${borderClass} my-4`
  const columnNumber = children.filter((item) => item.type === 'column').length
  const colXs = columnNumber === 2 ? 12 : 12
  const colSm = columnNumber === 2 ? 6 : 4

  return (
    <Row className={rowClasses}>
      {children.map(item => {
        if (item.type === 'column') {
          return (
            <Col className={align(item.props.align)} key={item.key} xs={colXs} sm={colSm}>{item.props.children.map(columnItem => {
              if (columnItem.type === 'amazonbutton') {
                return (
                  amazonButtonItem(columnItem)
                )
              } else if (columnItem.type === 'spacer') {
                return (
                  spacer(columnItem)
                )
              } else if (columnItem.type === 'counter') {
                return (
                  counter(columnItem)
                )
              } else if (columnItem.type === 'proscons') {
                return (
                  proscons(columnItem)
                )
              } else {
                return columnItem
              }
            })}</Col>
          )
        }

        if (item.type === 'amazonbutton') {
          return (
            amazonButtonItem(item)
          )
        }

        if (item.type === 'spacer') {
          return (
            spacer(item)
          )
        }

        if (item.type === 'counter') {
          return (
            counter(item)
          )
        }

        if (item.type === 'proscons') {
          return (
            proscons(item)
          )
        }
      })}
    </Row>
  )
}

export default Columns

import React from 'react'
import { Table } from 'reactstrap'
import './productTable.scss'

const renderRowsAndCols = (item) => {
  return (
    item.props.children.map(itemTr => {
      if (itemTr.type === 'xtr') {
        return (
          <tr key={itemTr.key}>
            {itemTr.props.children.map(itemTh => {
              if (itemTh.type === 'xth') {
                return (
                  <th key={itemTh.key}>{itemTh.props.children}</th>
                )
              }

              if (itemTh.type === 'xtd') {
                return (
                  <td key={itemTh.key}>{itemTh.props.children}</td>
                )
              }
            })}
          </tr>
        )
      }
    })
  )
}

const ProductTable = (props) => {
  const {children} = props
  return (
    <Table className="product__table">
      {children.map(item => {
        if (item.type === 'xthead') {
          return (
            <thead key={item.key}>
              {renderRowsAndCols(item)}
            </thead>
          )
        }

        if (item.type === 'xtbody') {
          return (
            <tbody key={item.key}>
              {renderRowsAndCols(item)}
            </tbody>
          )
        }
      })}
    </Table>
  )
}

export default ProductTable

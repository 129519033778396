import React from 'react'
import './counter.scss'

const Counter = (props) => {
  const { number = 0, subtitle = ''} = props

  return (
    <div className="counter-container">
      <div className="counter-number">{ number }</div>
      <div className="counter-subtitle">{ subtitle }</div>
    </div>
  )
}

export default Counter

import React from 'react'
import { Collapse, CardBody, Card, Badge } from 'reactstrap';
import './references.scss'

class References extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      collapsed: false
    }
  }

  toggle() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <Card className="references px-2 py-2">
        <div className="references__title"><strong>References</strong><Badge className="references__badge ml-2" onClick={this.toggle} color="light" outline="true">[{ this.state.collapsed ? 'Hide' : 'Show' }]</Badge></div>
        <div className="references__subtitle">Credibility is extremely important to us. See all research and references used in this article below.</div>
        <Collapse isOpen={this.state.collapsed}>
          <CardBody>
            {this.props.children.map(ref => {
              return (
                <div className="references__body--item"><ul>{ref}</ul></div>
              )
            })}
          </CardBody>
        </Collapse>
      </Card>
    )
  }
}

export default References
